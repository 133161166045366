//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'

export default {
  props: {
    blok: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    ...mapState([ 'locationList' ])
  },
  methods: {
    search(l) {
      console.log(l)
      alert(l)
    }
  }
}
